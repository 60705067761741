// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-duo-js": () => import("./../src/pages/duo.js" /* webpackChunkName: "component---src-pages-duo-js" */),
  "component---src-pages-etat-d-esprit-js": () => import("./../src/pages/etat-d-esprit.js" /* webpackChunkName: "component---src-pages-etat-d-esprit-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projets-js": () => import("./../src/pages/projets.js" /* webpackChunkName: "component---src-pages-projets-js" */),
  "component---src-templates-projets-blog-post-js": () => import("./../src/templates/projets/blog-post.js" /* webpackChunkName: "component---src-templates-projets-blog-post-js" */)
}

